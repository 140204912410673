<template>
  <v-container class="pa-0" fluid>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12">
        <v-card :img="branchData.site.cover ? branchData.site.cover : require('@/assets/images/grey-bg.jpg')" flat
                height="400" tile>
          <v-row align="center" class="h-100" justify="center" no-gutters>
            <v-col class="ma-auto" cols="12">
              <v-row align="center" justify="center" no-gutters>
                <v-avatar size="60px">
                  <v-img :src="branchData.site.logo"></v-img>
                </v-avatar>
                <span class="text-h3 white--text ms-5">{{ branchData.site.name }}</span>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="grade" cols="12">
        <v-card class="mx-auto rounded-lg overflow-hidden" color="primary" height="310" width="330">
          <v-row no-gutters>
            <v-col class="mt-6" cols="12">
              <kurcc-brand icon-color="white" text-color-class="white--text"/>
            </v-col>
            <v-col cols="12">
              <v-card-text>
                <div class="text-h4 white--text">{{ $vuetify.lang.t('$vuetify.pages.site.grade') }}</div>
                <div class="text-h2 font-weight-bold white--text">
                  {{ branchData.last_done_inspection ? branchData.last_done_inspection.grade : 'n/a' }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12">
              <v-card color="black" flat height="25" tile>
                <div class="white--text">www.kfi.com</div>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="mt-6" cols="auto">
        <v-card class="rounded-lg mx-3" height="230" width="330">
          <div class="text-h5 pt-5 font-weight-bold">{{ $vuetify.lang.t('$vuetify.pages.site.contact') }}</div>
          <v-card-text class="text-start">
            <v-card-subtitle class="py-2">
              <v-icon color="primary">mdi-phone-in-talk-outline</v-icon>
              {{ branchData.manager_phone_number }}
            </v-card-subtitle>
            <v-card-subtitle class="py-2">
              <v-icon color="primary">mdi-email-outline</v-icon>
              <a :href="`mailto:${branchData.site.email}`" class="ms-1">{{ branchData.site.email }}</a>
            </v-card-subtitle>
            <v-card-subtitle class="py-2">
              <v-icon color="primary">mdi-google-maps</v-icon>
              {{ branchData.full_address }}
            </v-card-subtitle>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="mt-6" cols="auto">
        <v-card class="rounded-lg mx-3" height="230" width="330">
          <div class="text-h5 pt-5 font-weight-bold">{{ $vuetify.lang.t('$vuetify.pages.site.siteInfo') }}</div>
          <v-card-text class="text-start">
            <v-card-subtitle class="py-2">
              <v-icon color="primary">mdi-bookmark-multiple-outline</v-icon>
              {{ $vuetify.lang.t('$vuetify.pages.site.category') }}: {{ branchData.site.category.name }}
            </v-card-subtitle>
            <v-card-subtitle class="py-2">
              <v-avatar size="25px">
                <v-img :src="branchData.site.logo"></v-img>
              </v-avatar>
              {{ $vuetify.lang.t('$vuetify.pages.site.brandName') }}: {{ branchData.site.name }}
            </v-card-subtitle>
            <v-card-subtitle class="py-2">
              <v-icon color="primary">mdi-star</v-icon>
              {{ $vuetify.lang.t('$vuetify.pages.site.userRating') }}:
              <v-icon>mdi-thumb-up-outline</v-icon>
              {{ branchData.likes_count }}
              <v-icon>mdi-thumb-down-outline</v-icon>
              {{ branchData.dislikes_count }}
            </v-card-subtitle>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card-title>{{ $vuetify.lang.t('$vuetify.pages.site.branchLocations') }}</v-card-title>
        <v-slide-group v-model="model" class="pa-4" show-arrows>
          <v-slide-item v-for="branch in branches" :key="branch.id">
            <v-sheet class="ma-4" max-width="450">
              <kurcc-branch-card :branch="branch"/>
            </v-sheet>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'KurccSiteBranchPage',
  props: ['branch_id', 'branch'],
  components: {
    KurccBrand: () => import('@/modules/app/components/KurccBrand'),
    KurccBranchCard: () => import('@/modules/home/components/KurccBranchCard')
  },
  data () {
    return {
      branchData: undefined,
      loadingBranches: false,
      model: null,
      branches: []
    }
  },
  methods: {
    getBranchData () {
      this.$store.dispatch('getBranch', { id: this.branch_id })
        .then(res => {
          this.branchData = res
          this.getAllBranches()
        })
    },
    getAllBranches () {
      this.loadingBranches = true
      this.$store.dispatch('getAllBranches', {
        perPage: 999999,
        currentPage: 1,
        withIncludes: true,
        additionalParams: [`site_ids=["${this.branchData.site.id}"]`]
      }).then(res => {
        this.branches = res.items
      }).finally(() => {
        this.loadingBranches = false
      })
    }
  },
  created () {
    if (this.branch_id) {
      if (this.branch) {
        this.branchData = this.branch
        this.getAllBranches()
      } else {
        this.getBranchData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.grade {
  margin-top: -130px;
}
</style>
